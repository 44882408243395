import * as React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';
import { FC, useState } from 'react';
import { z } from 'zod'
import { useForm, Controller, Control, Path } from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'

interface QuestionnaireGroupProps {
}

interface QuestionnaireGroupState {
    errorString: string;
    isLoading: boolean;
    isFault: boolean;
    isSubmited: boolean;
    isImageLoaded: boolean;
    formData: any;
    GDPRAccepted: boolean;
}

const EducationsProgramVG1DropdownOptions = [
    { key: 'Idrettsfag', text: 'Idrettsfag' },
    { key: 'Studiespesialisering', text: 'Studiespesialisering' },
    { key: 'Bygg- og anleggsteknikk', text: 'Bygg- og anleggsteknikk' },
    { key: 'Elektro og datateknologi', text: 'Elektro og datateknologi' },
    { key: 'Medier og kommunikasjon', text: 'Medier og kommunikasjon' },
    { key: 'Helse og oppvekstfag', text: 'Helse og oppvekstfag' },
    { key: 'Informasjonstekologi og medieproduksjon', text: 'Informasjonstekologi og medieproduksjon' },
    { key: 'Naturbruk', text: 'Naturbruk' },
    { key: 'Restaurant og matfag', text: 'Restaurant og matfag' },
    { key: 'Teknologi og industrifag', text: 'Teknologi og industrifag' },
    { key: 'Musikk dans og drama', text: 'Musikk dans og drama' },
    { key: 'Håndverk design og produktuvikling', text: 'Håndverk design og produktuvikling' },
    { key: 'Frisør blomster interiør og eksponeringsdesign', text: 'Frisør blomster interiør og eksponeringsdesign' },
    { key: 'Kunst og arkitetktur', text: 'Kunst og arkitetktur' }
];

const HighSchoolDropdownOptions = [
    { key: 'Ålesund VGS', text: 'Ålesund VGS' },
    { key: 'Fagerlia VGS', text: 'Fagerlia VGS' },
    { key: 'Spjelkavik VGS', text: 'Spjelkavik VGS' },
    { key: 'Borgund VGS', text: 'Borgund VGS' },
    { key: 'Akademiet VGS Ålesund', text: 'Akademiet VGS Ålesund' },
    { key: 'Akademiet Heltbert Toppidrettsgymnas', text: 'Akademiet Heltbert Toppidrettsgymnas' },
    { key: 'Ørsta VGS', text: 'Ørsta VGS' },
    { key: 'Ulstein VGS', text: 'Ulstein VGS' },
    { key: 'Volda VGS', text: 'Volda VGS' },
    { key: 'Stranda VGS', text: 'Stranda VGS' },
    { key: 'Sykkylven VGS', text: 'Sykkylven VGS' },
    { key: 'Vestborg VGS', text: 'Vestborg VGS' },
    { key: 'Herøy VGS', text: 'Herøy VGS' },
    { key: 'Herøy VGS avd Vanylven', text: 'Herøy VGS avd Vanylven' }
];


const NonEmptyStringSchema = (field: string) => z.string({errorMap: () => {return { message: `Vennligst fyll inn ${field}`}}}).min(1, `Vennligst fyll inn ${field}`)
const NonEmptyDropDownSchema = (field: string) => z.string({errorMap: () => {return {message: `Vennligst velg  ${field} `}}})
const NonEmptyNumberSchema = z.string().min(1, 'Kan ikke være tom').pipe(z.coerce.number({errorMap: () => {return {message: 'Vennligst legg inn ett tall'}}}).int('Vennligst legg inn ett tall').gte(0, 'Vennligst legg inn ett positivt tall'));

const QuestionnaireGroupSchema = z.object({
    NavnReiseansvarlig: NonEmptyStringSchema('navn for reiseansvarlig'),
    EMail: z.string({errorMap: () => {return {message: "Må være på format: ola.nordmann@domene.no"}}}).email('E-post må være på format: ola.nordmann@domene.no'),
    Telefonnummer: z.string({errorMap: () => {return {message: 'Vennligst legg inn telefonnummer  på følgende format: 12345678'}}}).length(8, 'Vennligst legg inn telefonnummer  på følgende format: 12345678'),
    Skole: NonEmptyDropDownSchema('skole'),
    UtdanningsprogramKlasse: NonEmptyDropDownSchema('utdanningsprogram/klasse'),
    Bankkontonummer: z.string({errorMap : () => {return {message: 'Vennligst legg inn bankkontonummer med 11 tall på følegende format: 12341212345'}}}).regex(new RegExp("^[0-9]{11}$")),
    Kontoinnehaver: NonEmptyStringSchema('kontoinnehaver'),
    AntallEleverSomDeltar: NonEmptyNumberSchema,
    Reisemal: NonEmptyStringSchema('reisemål'),
    ReisensVarighet: NonEmptyStringSchema('reisens varighet'),
    KortBeskrivelseAvPlanForReisen: NonEmptyStringSchema('kort beskrivelse av plan for reisen'),
    BudsjettForReisenPerDeltager: NonEmptyNumberSchema,
    SoknadsbelopPerDeltager: NonEmptyNumberSchema,
    Vedleggen: z.instanceof(File),
    Vedleggto: z.instanceof(File),
})

type QuestionnaireGroupType = z.infer<typeof QuestionnaireGroupSchema>;
export const QuestionnaireGroup: FC<QuestionnaireGroupProps> = () => {

    const [isSubmitted, setIsSumbitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFault, setIsFault] = useState(false);


    const { register, handleSubmit, formState: {errors}, control } = useForm<QuestionnaireGroupType>({
        resolver: zodResolver(QuestionnaireGroupSchema)
    })

    const onSubmit = async (object: any) => {

        setIsLoading(true);

        const data = new FormData();
        for(const key in object){
            data.set(key, object[key]);
        }

        const res = await fetch('https://mwe-noe-apim.azure-api.net/v1/httpTriggerFormData?ContentType=group',
            {
                method: 'POST',
                cache: 'no-cache',
                body: data
            });
        try {
            if (res.ok) {
                setIsFault(false);
                setIsLoading(false);
                setIsSumbitted(true);
            }
            else {
                setIsFault(true);
                setIsLoading(false);
                setIsSumbitted(false);
            }
        }
        catch (e: any) {
            console.log(e);
            setIsFault(true);
            setIsLoading(false);
            setIsSumbitted(false);
        }
    }


    return (
        <div className='container'>
            {!isSubmitted ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Søknadsskjema Michael Width Endresens Fond Gruppesøknader</h1>
                    <div>
                        Skal du reise sammen med en gruppe elever? Fyll ut skjemaet under. 
                        <br/>
                        NB! Hvis du ikke har har inntekt, send inn A08 - Mine inntekter og arbeidsforhold i PDF-fil. 
                        <br/>
                        <br/>
                    </div>
                    <Stack tokens={{ childrenGap: 50, maxWidth: 600 }} style={{maxWidth: 'none'}}>
                        <table>
                            <tbody>
                                 <tr>
                                    <td className='label'><label>Navn Reiseansvarlig *</label></td>
                                    <td style={{width: '70%'}}><TextField id="NavnReiseansvarlig" {...register('NavnReiseansvarlig')} errorMessage={errors.NavnReiseansvarlig?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>E-post *</label></td>
                                    <td className='widhtMedium'> <TextField  id="EMail" {...register('EMail')} errorMessage={errors.EMail?.message} /></td>
                                </tr> 
                                <tr>
                                    <td className='label'><label>Telefonnummer *</label></td>
                                    <td className='widhtMedium'><TextField id="Telefonnummer" {...register('Telefonnummer')} errorMessage={errors.Telefonnummer?.message} /></td>
                                </tr> 
                                <tr>
                                    <td className='label'><label>Skole *</label></td>
                                    <td style={{width: '70%'}}><ControlledDropdown control={control} name='Skole' id="Skole" options={HighSchoolDropdownOptions} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Utdanningsprogram/Klasse *</label></td>
                                    <td style={{width: '70%'}}><ControlledDropdown control={control} name='UtdanningsprogramKlasse' id="UtdanningsprogramKlasse" options={EducationsProgramVG1DropdownOptions}/></td>
                                </tr> 
                                <tr>
                                    <td className='label'><label>Bankkontonummer *</label></td>
                                    <td style={{width: '70%'}}><TextField id="Bankkontonummer" {...register('Bankkontonummer')} errorMessage={errors.Bankkontonummer?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Kontoinnehaver *</label></td>
                                    <td style={{width: '70%'}}><TextField id="Kontoinnehaver" {...register('Kontoinnehaver')} errorMessage={errors.Kontoinnehaver?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Antall elever som deltar på reisen *</label></td>
                                    <td style={{width: '70%'}}><TextField id="AntallEleverSomDeltar" {...register('AntallEleverSomDeltar')} errorMessage={errors.AntallEleverSomDeltar?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Reisemål *</label></td>
                                    <td style={{width: '70%'}}><TextField id="Reisemal" {...register('Reisemal')} errorMessage={errors.Reisemal?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Reisens varighet *</label></td>
                                    <td style={{width: '70%'}}><TextField id="ReisensVarighet" {...register('ReisensVarighet')} errorMessage={errors.ReisensVarighet?.message} /></td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Kort beskrivelse av plan for reisen *</label></td>
                                    <td style={{width: '70%'}}><textarea style={errors.KortBeskrivelseAvPlanForReisen?.message ? {border: '1px solid rgb(164, 38, 44)'} : {}} id="KortBeskrivelseAvPlanForReisen" {...register('KortBeskrivelseAvPlanForReisen')}/>
                                    {errors.KortBeskrivelseAvPlanForReisen?.message && <span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>{ errors.KortBeskrivelseAvPlanForReisen.message}</span>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='label'><label>Budsjett for reisen per deltager *</label></td>
                                    <td style={{width: '70%'}}><TextField id="BudsjettForReisenPerDeltager" {...register('BudsjettForReisenPerDeltager')} errorMessage={errors.BudsjettForReisenPerDeltager?.message} /></td>
                                </tr> 
                                <tr>
                                    <td className='label'><label>Søknadsbeløp per deltager *</label></td>
                                    <td style={{width: '70%'}}><TextField id="SoknadsbelopPerDeltager" {...register('SoknadsbelopPerDeltager')} errorMessage={errors.SoknadsbelopPerDeltager?.message} /></td>
                                </tr> 
                                 <tr>
                                    <td className='label'><label>Last opp deltagerliste *</label></td>
                                    <td>
                                        <Controller control={control} name="Vedleggen" render={({field}) =>
                                            <input id="Vedleggen" type="file" accept='.pdf, .xls, .xlsx' {...field} value={undefined} onChange={(e) => field.onChange(e.currentTarget.files?.[0]) } />
                                        } />
                                    </td>
                                </tr>
                                {errors.Vedleggen?.message ?
                                <tr>
                                    <td></td>
                                    <td><span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>Vennligst last opp deltagerliste</span></td>
                                </tr>
                                : ""}
                                <tr>
                                    <td className='label'><label>Last opp utfyllende søknad med program og budsjett *</label></td>
                                    <td>
                                        <Controller control={control} name="Vedleggto" render={({ field }) =>
                                        <input id="Vedleggto"  type="file" accept='.pdf, .xls, .xlsx' {...field} value={undefined} onChange={(e) => field.onChange(e.currentTarget.files?.[0]) }/>
                                    }/>
                                    </td>    
                                </tr>
                                {errors.Vedleggto?.message ?
                                <tr>
                                    <td></td>
                                    <td><span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>Vennligst last opp søknad med program og budsjett</span></td>
                                </tr>
                                : ""}
                            </tbody>
                        </table>
                        {isFault ?
                        <div style={{color: "red", marginTop: '0px'}}>
                            Noe gikk galt, prøv på nytt eller kontakt oss på E-post: <a style={{ textDecoration: 'none', color: 'rgb(164, 38, 44)'}} href="mailto:odd.gjortz@mwendresens.no">odd.gjortz@mwendresens.no</a>
                        </div> : ""}
                        <input style={{marginTop: '20px'}} className="btn btn-primary" type="submit" disabled={isLoading} value="Send søknad" />   
                    </Stack>
                </form> :
                <div>
                <center>
                    <div style={{ fontFamily: 'sans-serif', fontSize: 'x-large', paddingTop: '10%' }}>
                        <p>Takk for at du har sendt inn søknaden din!</p>
                        <p>En bekreftelse vil bli sendt til deg via e-post. Hvis du ikke mottar denne bekreftelsen, ber vi deg vennligst kontakte oss direkte på følgende e-postadresse: <a href="mailto:odd.gjortz@mwendresens.no">odd.gjortz@mwendresens.no</a>.</p>
                        <p>Vi ser frem til å behandle søknaden din.</p>
                        <p>Med vennlig hilsen,<br/>
                            Michael Width Endresens fond</p>
                    </div>
                </center>
            </div>
            }
        </div>
    );
}

const ControlledDropdown: FC<{ control: Control<QuestionnaireGroupType>, name: Path<QuestionnaireGroupType>, options: IDropdownOption[], id: string }> = ({ control, name, options, id }) => <Controller control={control} name={name} render={({ field, fieldState: { error } }) => <Dropdown options={options} {...field} errorMessage={error?.message}
    onChange={(e, chosenOption) => field.onChange(chosenOption?.key) } />} />

