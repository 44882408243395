import React from 'react';
import { Link } from 'react-router-dom';
import student from '../../image/students.png'

export default class Home extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <div className='topImage' style={{ backgroundImage: `url(${student})`}}/>
                </div>
                <div className='container'>
                    <h1>MICHAEL WIDTH ENDRESENS FOND FOR HANDEL OG HÅNDVERK PÅ SUNNMØRE OG I ÅLESUND</h1>
                    <p>
                        Skal du ut og reise i forbindelse med utdanning innen handel og/eller håndverk? Da kan du søke om reisestipend fra Michael Width Endresens fond.
                    </p>
                    <h5>
                        HVEM KAN SØKE OM REISESTIPEND? 
                    </h5>
                    <ul>
                        <li>Unge, evnerike og ubemidlede personer <strong>fra Sunnmøre</strong></li>
                        <li>Skal begynne, eller holder på med utdannelse innen handel og/eller håndverk</li>
                        <li>Utdanningen gjelder for videregående opplæring til høyre utdanning</li>
                        <li>Studiereisen må skje frem i tid</li>
                    </ul>
                    <hr/>
                    <h5>
                        HVORDAN SØKER MAN? 
                    </h5>
                    <p><strong>Som enkeltperson: </strong></p>
                    <ul>
                        <li>Fyll ut søknadsskjemaet <strong>"Enkelstående"</strong> under</li>
                        <li>Legg ved siste skatteoppgjør i PDF-fil. Har du ikke inntekt, skal du legge ved A08 - Mine inntekter og arbeidsforhold i PDF-fil </li>
                        <li>Legg ved en utfyllende søknad som inneholder mer informasjon om formålet med reisen, læringsmål og målsettinger (maks én side)</li>
                    </ul>
                    <p><strong>Som gruppe ved VGS: </strong></p>
                    <ul>
                        <li>Ansvarlig for studeiereisen (faglærer) fyller ut søknadsskjema <strong>«For grupper ved VGS»</strong> under</li>
                    </ul>
                    <hr/>
                    <h5>
                        HVA SKJER ETTER DU HAR SENDT INN SØKNAD?
                    </h5>
                    <p>Innen utgangen av mars året etter innsendt søknad, skal fondsstyret tildele stipend. Stipendet vil kunne innvilges etter vurdering på grunnlag av opplysninger i mottatt søknad. Stipendets størrelse fastsettes av styret for hvert enkelt tilfelle etter det konkrete behov. Kort tid etter dette vil du få en e-post med vedlegg som gjør rede for om du har blitt tildelt stipend og nødvendig dokumentasjon som skal sendes til styret.</p>
                    
                    <p className='mgt mgb'>
                        <strong>Søknadsfrist for tildeling av stipend er 31. desember hvert år. Bekjentgjørelse gjøres minst fire uker før søkefrist.</strong>
                        <br/>
                        <em>Ved å sende inn elektronisk søknadsskjema, bekrefter du at du godtar og samtykker i følgende <a className='link' href='https://mwewesa.blob.core.windows.net/resources/PERSONVERN%C3%86RKL%C3%86RING.pdf'>personvernerklæring.</a></em>
                    </p>
                    
                    <div className='buttonContainer'>
                        <Link to="/enkeltsoknader">
                            <button>Søknadsskema enkeltsående</button>
                        </Link>
                        <Link to="/gruppesoknader">
                            <button>Søknadsskema grupper ved VGS</button>
                        </Link>
                    </div>
                    <p><strong>Kontaktinfo: Tommy Sperre, tlf. 934 56 510, epost: <a className='link' href='mailto: sperre@aalesund-chamber.no'>sperre@aalesund-chamber.no</a> </strong></p>
                </div>
            </div>

        )
    }
}