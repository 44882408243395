import * as React from 'react';
import { Stack, TextField } from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';
import { FC, useState } from 'react';
import { z } from 'zod'
import { useForm, Controller, Control, Path } from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'

interface QuestionnaireProps {
}

const JaNeiDropdownOptions = [
    { key: 'Ja', text: 'Ja' },
    { key: 'Nei', text: 'Nei' }
];

const NonEmptyStringSchema = (field: string) => z.string({errorMap: () => {return { message: `Vennligst fyll inn ${field}`}}}).min(1, `Vennligst fyll inn ${field}`)
const NonEmptyDropDownSchema = (field: string) => z.string({errorMap: () => {return {message: `Vennligst velg  ${field} `}}});
const NonEmptyNumberSchema = z.string().min(1, 'Kan ikke være tom').pipe(z.coerce.number({errorMap: () => {return {message: 'Vennligst legg inn ett tall'}}}).int('Vennligst legg inn ett tall').gte(0, 'Vennligst legg inn ett positivt tall'));



const QuestionnaireSchema = z.object({
    FullName: NonEmptyStringSchema('fullt navn'),
    Fodselsdato: z.string({errorMap: () => {return { message: `Vennligst fyll inn fødsesdato på følgende format: 01.01.90`}}}).length(8, `Vennligst fyll inn fødsesdato på følgende format: 01.01.90`),
    Gateadresse: NonEmptyStringSchema('gateadresse'),
    WorkCity: NonEmptyStringSchema('postnummer og poststed'),
    EMail: z.string({errorMap: () => {return {message: "Må være på format: ola.nordmann@domene.no"}}}).email('E-post må være på format: ola.nordmann@domene.no'),
    CellPhone: z.string({errorMap: () => {return {message: 'Vennligst legg inn mobilnummer på følgende format: 12345678'}}}).length(8, 'Vennligst legg inn mobilnummer på følgende format: 12345678'),
    Bankkontonummer: z.string({errorMap : () => {return {message: 'Vennligst legg inn bankkontonummer med 11 tall på følegende format: 12341212345'}}}).regex(new RegExp("^[0-9]{11}$")),
    Kontoinnehaver: NonEmptyStringSchema('navn på kontoinnehaver'),
    SkoleLaerested: NonEmptyStringSchema('skole / lærested'),
    StudieretningSpesialisering: NonEmptyStringSchema('studieretning / spesialisering'),
    NettoFormueVedSistSkatteoppgjor: NonEmptyNumberSchema,
    AlminneligInntektVedSistSkatteop: NonEmptyNumberSchema,
    Reisemal: NonEmptyStringSchema('reisemål'),
    BeskrivKortReisensFormal: NonEmptyStringSchema('en kort beskrivelse av reisens formål'),
    ReisensVarighet: NonEmptyStringSchema('reisens varighet'),
    Soknadsbelop: NonEmptyNumberSchema,
    Budsjett: NonEmptyNumberSchema,
    Vedleggen: z.instanceof(File),
    Vedleggto: z.instanceof(File),
    HarDuMottattStotteFraFondetFor: NonEmptyDropDownSchema('om du har mottatt støtte fra fondet før eller ikke'),
    HvisDuHarMottattStotteTidligereB: z.string().optional()
 }).superRefine((data, context) => {
    if (data.HarDuMottattStotteFraFondetFor === 'Ja' && !data.HvisDuHarMottattStotteTidligereB) {
        context.addIssue({code: z.ZodIssueCode.custom, message: 'Vennligst fyll inn når og hvor mye støtte du har mottatt tidlegere', path: ['HvisDuHarMottattStotteTidligereB']})
    }
} )

type QuestionairType = z.infer<typeof QuestionnaireSchema>;
export const Questionnaire: FC<QuestionnaireProps> = () => {

    const [isSubmitted, setIsSumbitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFault, setIsFault] = useState(false);

    const { register,handleSubmit, formState: {errors}, control } = useForm<QuestionairType>({
        resolver: zodResolver(QuestionnaireSchema)
    })
    

    const onSubmit = async (object: any) => {

                setIsLoading(true);

                if(object['HarDuMottattStotteFraFondetFor'] === 'Ja'){
                    object['HarDuMottattStotteFraFondetFor'] = true
                } else {
                    object['HarDuMottattStotteFraFondetFor'] = false
                }

                const data = new FormData();
                for(const key in object){
                    data.set(key, object[key]);
                }

                const res = await fetch('https://mwe-noe-apim.azure-api.net/v1/httpTriggerFormData?ContentType=single',
                    {
                        method: 'POST',
                        cache: 'no-cache',
                        body: data
                    });
                try {
                    if (res.ok) {
                        setIsFault(false);
                        setIsLoading(false);
                        setIsSumbitted(true);
                    }
                    else {
                        setIsFault(true);
                        setIsLoading(false);
                        setIsSumbitted(false);
                    }
                }
                catch (e: any) {
                    console.log(e);
                    setIsFault(true);
                    setIsLoading(false);
                    setIsSumbitted(false);
                }
    }

    return (
        <div className='container'>
                {!isSubmitted ?
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>Søknadsskjema Michael Width Endresens Fond</h1>
                        <div>
                            Søk om reisestipend nå!
                            <br/>
                            Ved innsendelse av elektronisk søknad, bekrefter du ar du godtar og samtykker i personvernerklæring.
                            <br/>
                            <br/>
                            <br/>
                        </div>
                        <Stack tokens={{ childrenGap: 50, maxWidth: 600 }} style={{maxWidth: 'none'}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='label'><label>Fullt navn *</label></td>
                                    <td style={{ width: '70%' }}><TextField id="FullName" {...register('FullName')} errorMessage={errors.FullName?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Fødselsdato (6 siffer) *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Fodselsdato" {...register('Fodselsdato')} errorMessage={errors.Fodselsdato?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Gateadresse *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Gateadresse" {...register('Gateadresse')} errorMessage={errors.Gateadresse?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Postnummer og poststed *</label></td>
                                        <td style={{width: '70%'}}><TextField id="WorkCity" {...register('WorkCity')} errorMessage={errors.WorkCity?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>E-post *</label></td>
                                        <td className='widhtMedium'><TextField  id="EMail" {...register('EMail')} errorMessage={errors.EMail?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Mobilnummer *</label></td>
                                        <td className='widhtMedium'><TextField id="CellPhone" {...register('CellPhone')} errorMessage={errors.CellPhone?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Bankkontonummer  *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Bankkontonummer" {...register('Bankkontonummer')} errorMessage={errors.Bankkontonummer?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Navn på kontoinnehaver *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Kontoinnehaver" {...register('Kontoinnehaver')} errorMessage={errors.Kontoinnehaver?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Skole / lærested *</label></td>
                                        <td style={{width: '70%'}}><textarea style={errors.SkoleLaerested?.message ? {border: '1px solid rgb(164, 38, 44)'} : {}} id="SkoleLaerested" {...register('SkoleLaerested')}/>
                                        {errors.SkoleLaerested?.message && <span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>{ errors.SkoleLaerested.message}</span>}
                                    </td>    
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Studieretning / spesialisering *</label></td>
                                        <td style={{width: '70%'}}><textarea style={errors.StudieretningSpesialisering?.message ? {border: '1px solid rgb(164, 38, 44)'} : {}} id="StudieretningSpesialisering" {...register('StudieretningSpesialisering')} />
                                        {errors.StudieretningSpesialisering?.message && <span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>{ errors.StudieretningSpesialisering.message}</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Netto formue ved sist skatteoppgjør *</label></td>
                                        <td style={{width: '70%'}}><TextField id="NettoFormueVedSistSkatteoppgjor" {...register('NettoFormueVedSistSkatteoppgjor')} errorMessage={errors.NettoFormueVedSistSkatteoppgjor?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Alminnelig inntekt ved sist skatteoppgjør  *</label></td>
                                        <td style={{width: '70%'}}><TextField id="AlminneligInntektVedSistSkatteop" {...register('AlminneligInntektVedSistSkatteop')} errorMessage={errors.AlminneligInntektVedSistSkatteop?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Reisemål *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Reisemal" {...register('Reisemal')} errorMessage={errors.Reisemal?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Beskriv kort reisens formål *</label></td>
                                        <td style={{width: '70%'}}><textarea style={errors.BeskrivKortReisensFormal?.message ? {border: '1px solid rgb(164, 38, 44)'} : {}} id="BeskrivKortReisensFormal" {...register('BeskrivKortReisensFormal')}/>
                                        {errors.BeskrivKortReisensFormal?.message && <span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>{ errors.BeskrivKortReisensFormal.message}</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Reisens varighet *</label></td>
                                        <td style={{width: '70%'}}><TextField id="ReisensVarighet" {...register('ReisensVarighet')} errorMessage={errors.ReisensVarighet?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Søknadsbeløp *</label></td>
                                        <td style={{width: '70%'}}><TextField id="Soknadsbelop" {...register('Soknadsbelop')} errorMessage={errors.Soknadsbelop?.message} /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Budsjett for reisen *</label></td>
                                        <td style={{width: '70%'}}><textarea style={errors.Budsjett?.message ? {border: '1px solid rgb(164, 38, 44)'} : {}} id="Budsjett" {...register('Budsjett')} />
                                        {errors.Budsjett?.message && <span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>{ errors.Budsjett.message}</span>}
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td className='label'><label>Last opp siste års skatteoppgjør *</label></td>
                                    <td>
                                        <Controller control={control} name='Vedleggen' render={({ field }) => 
                                        <input id="Vedleggen" type="file" accept='.pdf, .xls, .xlsx' {...field} value={undefined} onChange={(e) => field.onChange(e.currentTarget.files?.[0]) } />
                                         }  
                                         />
                                        
                                    </td>
                                    </tr>
                                    {errors.Vedleggen?.message ?
                                    <tr>
                                        <td></td>
                                        <td><span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>Vennligst last opp siste års skatteoppgjør</span></td>
                                    </tr>
                                    : ""}
                                    <tr>
                                        <td className='label'><label>Last opp utfyllende søknad *</label></td>
                                        <td>
                                        <Controller control={control} name='Vedleggto' render={({ field }) => 
                                        <input id="Vedleggto"  type="file" accept='.pdf, .xls, .xlsx' {...field} value={undefined} onChange={(e) => field.onChange(e.currentTarget.files?.[0]) }/>
                                        } />
                                    </td>    
                                    </tr>
                                    {errors.Vedleggto?.message ?
                                    <tr>
                                        <td></td>
                                        <td><span style={{color: 'rgb(164, 38, 44)', fontSize: '12px', fontWeight: '400'}}>Vennligst last opp utfyllende søknad</span></td>
                                    </tr>
                                    : ""}
                                    <tr>
                                        <td className='label'><label>Har du mottatt støtte fra fondet før? *</label></td>
                                    <td style={{ width: '70%' }}><ControlledDropdown control={control} name='HarDuMottattStotteFraFondetFor' id="HarDuMottattStotteFraFondetFor" options={JaNeiDropdownOptions} 
                                    /></td>
                                    </tr>
                                    <tr>
                                        <td className='label'><label>Hvis du har mottatt støtte før, vennligst beskriv når og hvilke beløp</label></td>
                                        <td style={{width: '70%'}}><TextField id="HvisDuHarMottattStotteTidligereB" {...register('HvisDuHarMottattStotteTidligereB')} errorMessage={errors.HvisDuHarMottattStotteTidligereB?.message}/></td>
                                    </tr> 
                                </tbody>
                            </table>
                            {isFault ? 
                            <div style={{color: "rgb(164, 38, 44)", marginTop: '0px'}}>
                            Noe gikk galt, prøv på nytt eller kontakt oss på E-post: <a style={{ textDecoration: 'none', color: 'rgb(164, 38, 44)'}} href="mailto:odd.gjortz@mwendresens.no">odd.gjortz@mwendresens.no</a>
                            </div> : ""}
                        <input style={{marginTop: '0px'}} className="btn btn-primary" type="submit" disabled={isLoading} value="Send søknad" />   
                        </Stack>
                    </form> :
                    <div>
                    <center>
                        <div style={{ fontFamily: 'sans-serif', fontSize: 'x-large', paddingTop: '10%' }}>
                            <p>Takk for at du har sendt inn søknaden din!</p>
                            <p>En bekreftelse vil bli sendt til deg via e-post. Hvis du ikke mottar denne bekreftelsen, ber vi deg vennligst kontakte oss direkte på følgende e-postadresse: <a href="mailto:odd.gjortz@mwendresens.no">odd.gjortz@mwendresens.no</a>.</p>
                            <p>Vi ser frem til å behandle søknaden din.</p>
                            <p>Med vennlig hilsen,<br/>
                                Michael Width Endresens fond</p>
                        </div>
                    </center>
                </div>
                }
            </div>
    )
}


const ControlledDropdown: FC<{ control: Control<QuestionairType>, name: Path<QuestionairType>, options: IDropdownOption[], id: string }> = ({ control, name, options, id }) => <Controller control={control} name={name} render={({ field, fieldState: { error } }) => <Dropdown options={options} {...field} errorMessage={error?.message}
    onChange={(e, chosenOption) => field.onChange(chosenOption?.key) }/>} />
