import React from 'react';
import {Questionnaire} from './components/questionnaire/questionnaire';
import {QuestionnaireGroup} from './components/questionnaireGroup/questionnaireGroup';
import Home from './components/home/home';
import {Routes, Route } from "react-router-dom";
import './App.css';

function App() {

  return (
    <div className="App">
      <header className="App-header">
      </header>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/enkeltsoknader' element={<Questionnaire/>}/>
            <Route path='/gruppesoknader' element={<QuestionnaireGroup/>}/>
        </Routes>
    </div>
  );
}

export default App;
